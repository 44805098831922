export const sk = {
  car_valuation: "OCEŇOVANIE VOZIDLA",
  toggle_navigation: "Prepnúť navigáciu",
  vin: "VIN",
  plate: "Tabuľka",
  mileage: "Počet najazdených kilometrov",
  first_reg_date: "dátum prvej registrácie",
  check_valuation: "Skontrolujte cenovú ponuku",
  contact: "Kontakt",
  privacy_policy: "zásady ochrany osobných údajov",
  regulations: "štatút",
  valuation: "Oceňovanie",
  min_price: "Minimálna cena",
  med_price: "Priemerná cena",
  max_price: "Maximálna cena",
  we_have_report_for_you: "Máme prehľad pre vaše vozidlo!",
  incident_history: "História incidentov",
  odometer_reading: "Čítanie kilometrov",
  vehicles_inspection: "Kontroly vozidiel",
  archive_photos: "Archívne fotografie",
  check_now: "SKONTROLUJ TERAZ!",
  unfortunately_not_found_desc:
    "Bohužiaľ sme nenašli rok výroby tohto vozidla, zadajte ho do poľa vyššie.",
  type_odometer:
    "Zadajte aktuálny stav najazdených kilometrov vozidla v kilometroch pre lepší odhad jeho hodnoty.",
  check_another_vin: "Skontrolujte iné VIN",
  type_plate: "Zadajte poznávaciu značku vozidla.",
  type_first_reg_date: "Zadajte dátum prvej registrácie vozidla.",
  price_odometer: "Cena / najazdené kilometre",
  price_year: "Cena / rok",
  year: "rok",
  vehicle_history_report: "Správa o histórii vozidla",
  click: "Kliknite",
  here: "Tu",
  and_check_vehicle_history: "a skontrolujte históriu vozidla svojho auta",
  decode_your_number: "Dekódujte svoje číslo",
  for_free: "úplne zadarmo",
  check_vehicle_valuation:
    "Skontrolujte si ocenenie svojho vozidla na webovej stránke",
  stolen_cars_database: "Databáza ukradnutých vozidiel",
  check_if_your_vehicle_is_in_stolen_database: "Skontrolujte, či vozidlo vidí požadované zariadenia",
  link: "odkaz",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - číslo podvozku",
  first_reg_plate: "dátum prvej registrácie",
  get_discount_code: "Získajte zľavový kód",
  incidnets_history: "História incidentov",
  vehicle_odometer: "Počet najazdených kilometrov vozidla",
  vehicle_inspection: "Kontrola vozidla",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Skontrolujte si prosím správnosť vášho VIN čísla",
  enter_your_vin_number: "Zadajte svoje VIN číslo",
  result_of_valid_vin_number: "Výsledok správnosti VIN čísla",
  entered_number: "Zadané číslo",
  proved_to_be: "ukázal sa byť",
  correct: "Správne",
  incorrect: "Nesprávne",
  entered_vin_number: "zadané VIN číslo",
  corrected_vin_number: "Upravené VIN číslo",
  alternatives_vin_list_numbers: "Alternatívny zoznam VIN čísel",
  check_and_clicked_interested_vin_number: "",
  check: "Skontrolujte",
  bg: "Bulharčina",
  ar: "Arabčina",
  cs: "Český",
  da: "Dánčina",
  de: "Nemecký",
  el: "Grécky",
  en: "Britský",
  es: "Španielčina",
  fr: "Francúzsky",
  hu: "Maďarský",
  it: "Taliansky",
  lt: "Litovský",
  nl: "Holandský",
  no: "Nórsky",
  pl: "Poľský",
  pt: "Portugalčina",
  ro: "Rumunský",
  ru: "Ruský",
  sk: "Slovenský",
  sr: "Srbský",
  sv: "Svédsky",
  tr: "Turecký",
  uk: "Ukrajinčina",
};
