export const fr = {
  car_valuation: "ÉVALUATION DU VÉHICULE",
  toggle_navigation: "Basculer la navigation",
  vin: "NIV",
  plate: "Table",
  mileage: "Kilométrage",
  first_reg_date: "date de première inscription",
  check_valuation: "Vérifier le devis",
  contact: "Contactez",
  privacy_policy: "politique de confidentialité",
  regulations: "Loi",
  valuation: "Évaluation",
  min_price: "Prix minime",
  med_price: "Prix moyen",
  max_price: "Prix maximal",
  we_have_report_for_you: "Nous avons un rapport pour votre véhicule!",
  incident_history: "Historique des incidents",
  odometer_reading: "Lecture du kilométrage",
  vehicles_inspection: "Inspections de véhicules",
  archive_photos: "Photos d'archives",
  check_now: "VÉRIFIE MAINTENANT!",
  unfortunately_not_found_desc:
    "Malheureusement, nous n'avons pas trouvé l'année de production de ce véhicule, veuillez l'entrer dans le champ ci-dessus.",
  type_odometer:
    "Entrez le kilométrage actuel du véhicule en kilomètres pour une meilleure estimation de sa valeur.",
  check_another_vin: "Vérifier un autre NIV",
  type_plate: "Entrez la plaque d'immatriculation du véhicule.",
  type_first_reg_date:
    "Entrez la date de la première immatriculation du véhicule.",
  price_odometer: "Prix / kilométrage",
  price_year: "Prix / an",
  year: "An",
  vehicle_history_report: "Rapport d'historique de véhicule",
  click: "Cliquez sur",
  here: "Ici",
  and_check_vehicle_history:
    "et vérifiez l'historique du véhicule de votre voiture",
  decode_your_number: "Décodez votre numéro",
  for_free: "complètement libre",
  check_vehicle_valuation:
    "Vérifiez l'estimation de votre véhicule sur le site",
  stolen_cars_database: "Base de données des véhicules volés",
  check_if_your_vehicle_is_in_stolen_database: "Vérifiez si le véhicule voit les appareils recherchés",
  link: "lien",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - numéro de châssis",
  first_reg_plate: "date de première inscription",
  get_discount_code: "Obtenez un code de réduction",
  incidnets_history: "Historique des incidents",
  vehicle_odometer: "Kilométrage du véhicule",
  vehicle_inspection: "Inspection de véhicule",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Veuillez vérifier l'exactitude de votre numéro VIN",
  enter_your_vin_number: "Entrez votre numéro VIN",
  result_of_valid_vin_number: "Le résultat de l'exactitude du numéro VIN",
  entered_number: "Numéro saisi",
  proved_to_be: "il s'est avéré être",
  correct: "Corriger",
  incorrect: "Incorrect",
  entered_vin_number: "Numéro VIN entré",
  corrected_vin_number: "Numéro VIN révisé",
  alternatives_vin_list_numbers: "Liste de numéros VIN alternatifs",
  check_and_clicked_interested_vin_number: "",
  check: "Vérifier",
  bg: "Bulgare",
  ar: "Arabe",
  cs: "Tchèque",
  da: "Danois",
  de: "Allemand",
  el: "Grec",
  en: "Britanique",
  es: "Espagnol",
  fr: "Français",
  hu: "Hongrois",
  it: "Italien",
  lt: "Lituanien",
  nl: "Néerlandais",
  no: "Norvégien",
  pl: "Polonais",
  pt: "Portugais",
  ro: "Roumain",
  ru: "Russe",
  sk: "Slovaque",
  sr: "Serbe",
  sv: "Suédois",
  tr: "Turc",
  uk: "Ukrainien",
};
