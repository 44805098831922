<template>
  <div class="container">
    <div>
      <div class="row">
        <div class="col-12">
          <h5>{{ t("entered_vin_number") }}:</h5>
        </div>
        <div class="col-12">
          <ul class="
                list-group
                d-flex d-xl-flex
                justify-content-center
                align-items-center
              ">
            <li class="list-group-item d-xl-flex justify-content-xl-center" style="width: 30%">
              <span style="cursor: default" v-html="vinOrginalUnderscore"></span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h5>{{ t("corrected_vin_number") }}:</h5>
        </div>
        <div class="col-12">
          <ul class="
                list-group
                d-flex d-xl-flex
                justify-content-center
                align-items-center
              ">
            <li @click="sendForm(vinCorrect, token)" class="
                  list-group-item
                  d-xl-flex
                  justify-content-xl-center
                  check
                " style="width: 30%">
              <span v-html="vinCorrectUnderscore"></span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="alternativesList.length > 0" class="row mt-5">
        <div class="col-12">
          <h5>{{ t("alternatives_vin_list_numbers") }}:</h5>
        </div>
        <div class="col-12">
          <ul class="
                list-group
                d-flex d-xl-flex
                justify-content-center
                align-items-center
              ">
            <li v-for="altVin in alternativesList" @click="sendForm(altVin, token)" :key="altVin" class="
                  list-group-item
                  d-xl-flex
                  justify-content-xl-center
                  check
                " style="width: 30%">
              <span>{{ altVin }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "VinResults",
  props: {
    valid: Boolean,
    result: Object,
  },
  setup(props) {
    const vinOrginal = props.result
      ? toRef(props.result.vin_orginal)._object
      : ref("");
    const vinCorrect = props.result
      ? toRef(props.result.vin_correct)._object
      : ref("");
    const alternativesList =
      props.result && props.result.vin_alternatives_list
        ? toRef(props.result.vin_alternatives_list)._object
        : ref([]);

    const store = useStore();

    const vinOrginalUnderscore = ref("");
    const vinCorrectUnderscore = ref("");

    const { t } = useI18n();

    const underscoreDiff = (vin, compare, color) => {
      let underscored = "";

      for (let i = 0; i < vin.length; i++) {
        if (vin[i] === compare[i]) {
          underscored += vin[i];
        } else {
          underscored += `<u class="${color}">${vin[i]}</u>`;
        }
      }

      return underscored;
    };

    const token = computed(() => {
      return store.state.token;
    });

    const sendForm = async (vin, token) => {
      store.dispatch("sendRequest", { vin, token });
    };

    onMounted(() => {
      vinOrginalUnderscore.value = underscoreDiff(
        vinOrginal,
        vinCorrect,
        "text-danger"
      );
      vinCorrectUnderscore.value = underscoreDiff(
        vinCorrect,
        vinOrginal,
        "text-success"
      );
    });

    return {
      vinOrginalUnderscore,
      vinCorrectUnderscore,
      alternativesList,
      vinCorrect,
      sendForm,
      token,
      t,
    };
  },
};
</script>

<style scoped>
.check:hover {
  background-color: #efefef;
  transition: 0.2s;
  cursor: pointer;
}
</style>