export const no = {
  car_valuation: "VURDERING AV KJØRETØY",
  toggle_navigation: "Slå på navigering",
  vin: "VIN",
  plate: "Bord",
  mileage: "Kilometerstand",
  first_reg_date: "dato for første registrering",
  check_valuation: "Sjekk sitatet",
  contact: "Ta kontakt med",
  privacy_policy: "personvernerklæring",
  regulations: "vedtekter",
  valuation: "Verdivurdering",
  min_price: "Minimal pris",
  med_price: "Gjennomsnittspris",
  max_price: "Makspris",
  we_have_report_for_you: "Vi har en rapport for kjøretøyet ditt!",
  incident_history: "Hendelseshistorie",
  odometer_reading: "Kilometeravlesning",
  vehicles_inspection: "Kjøretøykontroller",
  archive_photos: "Arkivbilder",
  check_now: "SJEKK NÅ!",
  unfortunately_not_found_desc:
    "Dessverre har vi ikke funnet produksjonsåret for dette kjøretøyet, vennligst skriv det inn i feltet ovenfor.",
  type_odometer:
    "Angi gjeldende kjørelengde for kjøretøyet i kilometer for et bedre estimat av verdien.",
  check_another_vin: "Sjekk en annen VIN",
  type_plate: "Skriv inn bilskiltet til kjøretøyet.",
  type_first_reg_date:
    "Skriv inn datoen for første registrering av kjøretøyet.",
  price_odometer: "Pris / kjørelengde",
  price_year: "Pris / år",
  year: "År",
  vehicle_history_report: "Bilhistorierapport",
  click: "Klikk",
  here: "Her",
  and_check_vehicle_history: "og sjekk bilens kjøretøyhistorikk",
  decode_your_number: "Dekode nummeret ditt",
  for_free: "helt gratis",
  check_vehicle_valuation:
    "Sjekk verdsettelsen av kjøretøyet ditt på nettsiden",
  stolen_cars_database: "Database over stjålne kjøretøy",
  check_if_your_vehicle_is_in_stolen_database: "Sjekk om kjøretøyet ser ønskede enheter",
  link: "link",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - chassisnummer",
  first_reg_plate: "dato for første registrering",
  get_discount_code: "Få en rabattkode",
  incidnets_history: "Hendelseshistorie",
  vehicle_odometer: "Kjøretøyets kjørelengde",
  vehicle_inspection: "Kjøretøykontroll",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Vennligst sjekk riktigheten av VIN-nummeret ditt",
  enter_your_vin_number: "Skriv inn VIN-nummeret ditt",
  result_of_valid_vin_number: "Resultatet av riktigheten av VIN-nummeret",
  entered_number: "Oppgitt nummer",
  proved_to_be: "han viste seg å være",
  correct: "Riktig",
  incorrect: "stemmer ikke",
  entered_vin_number: "VIN-nummer oppgitt",
  corrected_vin_number: "Revidert VIN-nummer",
  alternatives_vin_list_numbers: "Alternative VIN-nummerliste",
  check_and_clicked_interested_vin_number: "",
  check: "Kryss av",
  bg: "Bulgarsk",
  ar: "Arabisk",
  cs: "Tsjekkisk",
  da: "Dansk",
  de: "Tysk",
  el: "Gresk",
  en: "Britisk",
  es: "Spansk",
  fr: "Fransk",
  hu: "Ungarsk",
  it: "Italiensk",
  lt: "Litauisk",
  nl: "Nederlandsk",
  no: "Norsk",
  pl: "Pusse",
  pt: "Portugisisk",
  ro: "Rumensk",
  ru: "Russisk",
  sk: "Slovakisk",
  sr: "Serbisk",
  sv: "Svensk",
  tr: "Tyrkisk",
  uk: "Ukrainsk",
};
