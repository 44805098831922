import { ref } from "vue";
import axios from "axios";

export default function reqStartUp() {
  const response = ref({});

  const sendReqStartUp = async (vin = null, token) => {
    try {
      response.value = await axios.post("/api/startUp", {
        vin: vin || "",
        token: token,
      });

      if (response.value.data.status != "error") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  return {
    sendReqStartUp,
    response,
  };
}
