export const hu = {
  car_valuation: "GÉPJÁRMŰ ÉRTÉKELÉS",
  toggle_navigation: "Kapcsolja be a navigációt",
  vin: "VIN",
  plate: "asztal",
  mileage: "Futásteljesítmény",
  first_reg_date: "az első regisztráció dátuma",
  check_valuation: "Ellenőrizze az árajánlatot",
  contact: "Kapcsolatba lépni",
  privacy_policy: "Adatvédelmi irányelvek",
  regulations: "Törvény",
  valuation: "Értékelés",
  min_price: "Minimális ár",
  med_price: "Átlag ár",
  max_price: "Maximális ár",
  we_have_report_for_you: "Bejelentést készítünk járművéről!",
  incident_history: "Eseménytörténet",
  odometer_reading: "Futásteljesítmény leolvasás",
  vehicles_inspection: "Gépjárművek átvizsgálása",
  archive_photos: "Archív fotók",
  check_now: "ELLENŐRIZD MOST!",
  unfortunately_not_found_desc:
    "Sajnos ennek a járműnek a gyártási évét nem találtuk, kérjük írja be a fenti mezőbe.",
  type_odometer:
    "Adja meg a jármű aktuális futásteljesítményét kilométerben, hogy jobban megbecsülje értékét.",
  check_another_vin: "Ellenőrizze egy másik VIN-t",
  type_plate: "Adja meg a jármű rendszámát.",
  type_first_reg_date: "Adja meg a jármű első regisztrációjának dátumát.",
  price_odometer: "Ár / futásteljesítmény",
  price_year: "Ár / év",
  year: "Év",
  vehicle_history_report: "Járműtörténeti jelentés",
  click: "Kattintson",
  here: "Itt",
  and_check_vehicle_history: "és ellenőrizze autója járműtörténetét",
  decode_your_number: "Dekódolja a számát",
  for_free: "teljesen ingyenes",
  check_vehicle_valuation: "Ellenőrizze járműve értékét a weboldalon",
  stolen_cars_database: "Ellopott járművek adatbázisa",
  check_if_your_vehicle_is_in_stolen_database: "Ellenőrizze, hogy a jármű lát-e keresett eszközöket",
  link: "link",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - alvázszám",
  first_reg_plate: "az első regisztráció dátuma",
  get_discount_code: "Szerezzen kedvezményes kódot",
  incidnets_history: "Eseménytörténet",
  vehicle_odometer: "A jármű futásteljesítménye",
  vehicle_inspection: "Gépjármű átvizsgálás",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Kérjük, ellenőrizze a VIN-szám helyességét",
  enter_your_vin_number: "Adja meg VIN-számát",
  result_of_valid_vin_number: "A VIN-szám helyességének eredménye",
  entered_number: "Beírt szám",
  proved_to_be: "kiderült, hogy az",
  correct: "Helyes",
  incorrect: "Helytelen",
  entered_vin_number: "VIN szám megadva",
  corrected_vin_number: "Átdolgozott VIN szám",
  alternatives_vin_list_numbers: "Alternatív VIN-számok listája",
  check_and_clicked_interested_vin_number: "",
  check: "Jelölje be",
  bg: "Bolgár",
  ar: "Arab",
  cs: "Cseh",
  da: "Dán",
  de: "Német",
  el: "Görög",
  en: "Angol",
  es: "Spanyol",
  fr: "Francia",
  hu: "Magyar",
  it: "Olasz",
  lt: "Litván",
  nl: "Holland",
  no: "Norvég",
  pl: "Fényesít",
  pt: "Portugál",
  ro: "Román",
  ru: "Orosz",
  sk: "Szlovák",
  sr: "Szerb",
  sv: "Svéd",
  tr: "Török",
  uk: "Ukrán",
};
