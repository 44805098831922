import Home from "../views/Home";
import Regulations from "../components/Regulations";
import Contact from '../views/Contact';

export default [
  { path: "/", name: "home", component: Home },
  { path: "/index.html", component: Home },
  { path: "/index.htm", component: Home },
  { path: "/index", component: Home },
  { path: "/regulations", name: "regulations", component: Regulations },
  { path: "/contact", name: "contact", component: Contact },
  { path: "/:catchAll(.*)", component: Home },
];
