export const uk = {
  car_valuation: "ОЦІНКА АВТОМОБІЛЯ",
  toggle_navigation: "Увімкнути навігацію",
  vin: "VIN номер",
  plate: "Таблиця",
  mileage: "Пробіг",
  first_reg_date: "дата першої реєстрації",
  check_valuation: "Перевірте цитату",
  contact: "Контакт",
  privacy_policy: "політика конфіденційності",
  regulations: "Статут",
  valuation: "Оцінка",
  min_price: "Мінімальна ціна",
  med_price: "Середня ціна",
  max_price: "Максимальна ціна",
  we_have_report_for_you: "У нас є звіт для вашого автомобіля!",
  incident_history: "Історія інциденту",
  odometer_reading: "Зчитування пробігу",
  vehicles_inspection: "Перевірки транспортних засобів",
  archive_photos: "Архівні фото",
  check_now: "ПЕРЕВІРЬ ЗАРАЗ!",
  unfortunately_not_found_desc:
    "На жаль, ми не знайшли рік виробництва цього транспортного засобу, введіть його в поле вище.",
  type_odometer:
    "Введіть поточний пробіг автомобіля в кілометрах, щоб краще оцінити його вартість.",
  check_another_vin: "Перевірте інший VIN",
  type_plate: "Введіть номерний знак транспортного засобу.",
  type_first_reg_date: "Введіть дату першої реєстрації транспортного засобу.",
  price_odometer: "Ціна / пробіг",
  price_year: "Ціна / рік",
  year: "рік",
  vehicle_history_report: "Звіт про історію автомобіля",
  click: "Натисніть",
  here: "тут",
  and_check_vehicle_history: "і перевірити історію автомобіля",
  decode_your_number: "Розшифруйте свій номер",
  for_free: "абсолютно безкоштовно",
  check_vehicle_valuation: "Перевірте оцінку вашого автомобіля на сайті",
  stolen_cars_database: "База даних викрадених автомобілів",
  check_if_your_vehicle_is_in_stolen_database: "Перевірте, чи бачить автомобіль потрібні пристрої",
  link: "посилання",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - номер шасі",
  first_reg_plate: "дата першої реєстрації",
  get_discount_code: "Отримайте код знижки",
  incidnets_history: "Історія інциденту",
  vehicle_odometer: "Пробіг автомобіля",
  vehicle_inspection: "Огляд автомобіля",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Будь ласка, перевірте правильність свого номера VIN",
  enter_your_vin_number: "Введіть свій номер VIN",
  result_of_valid_vin_number: "Результат правильності номера VIN",
  entered_number: "Введене число",
  proved_to_be: "він виявився",
  correct: "Правильно",
  incorrect: "Невірно",
  entered_vin_number: "Введено номер VIN",
  corrected_vin_number: "Виправлений номер VIN",
  alternatives_vin_list_numbers: "Список альтернативних номерів VIN",
  check_and_clicked_interested_vin_number: "",
  check: "Перевірте",
  bg: "Болгарська",
  ar: "Арабська",
  cs: "Чеська",
  da: "Датська",
  de: "Німецький",
  el: "Грецька",
  en: "Британець",
  es: "Іспанська",
  fr: "Французька",
  hu: "Угорська",
  it: "Італійська",
  lt: "Литовський",
  nl: "Голландська",
  no: "Норвезька",
  pl: "Польський",
  pt: "Португальська",
  ro: "Румунська",
  ru: "Російський",
  sk: "Словацький",
  sr: "Сербська",
  sv: "Шведська",
  tr: "Турецька",
  uk: "Українська",
};
