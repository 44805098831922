export const nl = {
  car_valuation: "VOERTUIG WAARDERING",
  toggle_navigation: "Navigatie in-/uitschakelen",
  vin: "VIN",
  plate: "Tafel",
  mileage: "Kilometerstand",
  first_reg_date: "datum eerste inschrijving",
  check_valuation: "Bekijk de offerte",
  contact: "Contact",
  privacy_policy: "privacybeleid",
  regulations: "Statuut",
  valuation: "waardering",
  min_price: "Minimale prijs",
  med_price: "Gemiddelde prijs",
  max_price: "Maximale prijs",
  we_have_report_for_you: "We hebben een rapport voor uw voertuig!",
  incident_history: "incident geschiedenis",
  odometer_reading: "Kilometerstand:",
  vehicles_inspection: "Voertuiginspecties",
  archive_photos: "Archieffoto's",
  check_now: "CHECK NU!",
  unfortunately_not_found_desc:
    "Helaas hebben we het productiejaar van dit voertuig niet gevonden, vul het in het veld hierboven in.",
  type_odometer:
    "Voer de huidige kilometerstand van het voertuig in kilometers in voor een betere schatting van de waarde.",
  check_another_vin: "Controleer een ander VIN",
  type_plate: "Vul het kenteken van het voertuig in.",
  type_first_reg_date:
    "Vul de datum van de eerste registratie van het voertuig in.",
  price_odometer: "Prijs / kilometerstand",
  price_year: "Prijs / jaar",
  year: "Jaar",
  vehicle_history_report: "Voertuiggeschiedenisrapport",
  click: "Klik",
  here: "Hier",
  and_check_vehicle_history:
    "en controleer de voertuiggeschiedenis van uw auto",
  decode_your_number: "Ontcijfer je nummer",
  for_free: "helemaal gratis",
  check_vehicle_valuation: "Controleer de waarde van uw voertuig op de website",
  stolen_cars_database: "Database van gestolen voertuigen",
  check_if_your_vehicle_is_in_stolen_database: "Controleer of het voertuig gewenste apparaten ziet",
  link: "koppeling",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - chassisnummer",
  first_reg_plate: "datum eerste inschrijving",
  get_discount_code: "Ontvang een kortingscode",
  incidnets_history: "Geschiedenis van incidenten",
  vehicle_odometer: "Kilometerstand van het voertuig",
  vehicle_inspection: "Voertuiginspectie",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Controleer de juistheid van uw VIN-nummer",
  enter_your_vin_number: "Voer uw VIN-nummer in",
  result_of_valid_vin_number:
    "Het resultaat van de juistheid van het VIN-nummer",
  entered_number: "Ingevoerd nummer",
  proved_to_be: "hij bleek te zijn",
  correct: "Corrigeren",
  incorrect: "Niet correct",
  entered_vin_number: "VIN-nummer ingevoerd",
  corrected_vin_number: "Herzien VIN-nummer",
  alternatives_vin_list_numbers: "Lijst met alternatieve VIN-nummers",
  check_and_clicked_interested_vin_number: "",
  check: "Controleren",
  bg: "Bulgaars",
  ar: "Arabisch",
  cs: "Tsjechisch",
  da: "Deens",
  de: "Duits",
  el: "Grieks",
  en: "Brits",
  es: "Spaans",
  fr: "Frans",
  hu: "Hongaars",
  it: "Italiaans",
  lt: "Litouws",
  nl: "Nederlands",
  no: "Noors",
  pl: "Pools",
  pt: "Portugees",
  ro: "Roemeense",
  ru: "Russisch",
  sk: "Slowaaks",
  sr: "Servisch",
  sv: "Zweeds",
  tr: "Turks",
  uk: "Oekraïens",
};
