export const es = {
  car_valuation: "VALORACIÓN DEL VEHÍCULO",
  toggle_navigation: "Navegación de palanca",
  vin: "VIN",
  plate: "Mesa",
  mileage: "Kilometraje",
  first_reg_date: "fecha de la primera matriculación",
  check_valuation: "Consulta la cotización",
  contact: "Contacto",
  privacy_policy: "política de privacidad",
  regulations: "Estatuto",
  valuation: "Valuación",
  min_price: "precio minimo",
  med_price: "Precio promedio",
  max_price: "Precio máximo",
  we_have_report_for_you: "¡Tenemos un reporte para tu vehículo!",
  incident_history: "Historial de incidentes",
  odometer_reading: "lectura de kilometraje",
  vehicles_inspection: "Inspecciones de vehículos",
  archive_photos: "Fotos de archivo",
  check_now: "¡REVISALO AHORA!",
  unfortunately_not_found_desc:
    "Desafortunadamente, no hemos encontrado el año de producción de este vehículo, introdúzcalo en el campo de arriba.",
  type_odometer:
    "Ingrese el kilometraje actual del vehículo en kilómetros para una mejor estimación de su valor.",
  check_another_vin: "Comprobar un VIN diferente",
  type_plate: "Introduzca la matrícula del vehículo.",
  type_first_reg_date:
    "Introduzca la fecha de la primera matriculación del vehículo.",
  price_odometer: "Precio / kilometraje",
  price_year: "Precio / año",
  year: "Año",
  vehicle_history_report: "Informe del historial del vehículo",
  click: "Hacer clic",
  here: "Aquí",
  and_check_vehicle_history: "y consulta el historial vehicular de tu coche",
  decode_your_number: "Decodifica tu número",
  for_free: "completamente libre",
  check_vehicle_valuation: "Consulta la valoración de tu vehículo en la web",
  stolen_cars_database: "Base de datos de vehículos robados",
  check_if_your_vehicle_is_in_stolen_database: "Comprobar si el vehículo detecta dispositivos buscados",
  link: "Enlace",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - número de chasis",
  first_reg_plate: "fecha de la primera matriculación",
  get_discount_code: "Obtén un código de descuento",
  incidnets_history: "Historial de incidentes",
  vehicle_odometer: "kilometraje del vehículo",
  vehicle_inspection: "Inspeccion de vehiculo",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Por favor, compruebe la exactitud de su número VIN",
  enter_your_vin_number: "Ingrese su número VIN",
  result_of_valid_vin_number: "El resultado de la corrección del número VIN.",
  entered_number: "Número ingresado",
  proved_to_be: "resultó ser",
  correct: "Correcto",
  incorrect: "Incorrecto",
  entered_vin_number: "Número VIN ingresado",
  corrected_vin_number: "Número VIN revisado",
  alternatives_vin_list_numbers: "Lista de números VIN alternativos",
  check_and_clicked_interested_vin_number: "",
  check: "Controlar",
  bg: "Búlgaro",
  ar: "Arábica",
  cs: "Checo",
  da: "Danés",
  de: "Alemán",
  el: "Griego",
  en: "Británico",
  es: "Español",
  fr: "Francés",
  hu: "Húngaro",
  it: "Italiano",
  lt: "Lituano",
  nl: "Holandés",
  no: "Noruego",
  pl: "Polaco",
  pt: "Portugués",
  ro: "Rumano",
  ru: "Ruso",
  sk: "Eslovaco",
  sr: "Serbio",
  sv: "Sueco",
  tr: "Turco",
  uk: "Ucranio",
};
