export const sr = {
  car_valuation: "ВРЕДНОВАЊЕ ВОЗИЛА",
  toggle_navigation: "Пребаци навигацију",
  vin: "ВИН",
  plate: "Сто",
  mileage: "Километража",
  first_reg_date: "датум прве регистрације",
  check_valuation: "Проверите цитат",
  contact: "Контакт",
  privacy_policy: "правила о приватности",
  regulations: "Статут",
  valuation: "Вредновање",
  min_price: "Минимална цена",
  med_price: "Просечна цена",
  max_price: "Максимална цена",
  we_have_report_for_you: "Имамо извештај за ваше возило!",
  incident_history: "Историја инцидента",
  odometer_reading: "Очитавање километраже",
  vehicles_inspection: "Прегледи возила",
  archive_photos: "Архивске фотографије",
  check_now: "ПРОВЕРИТЕ САДА!",
  unfortunately_not_found_desc:
    "Нажалост, нисмо пронашли годину производње за ово возило, унесите је у поље изнад.",
  type_odometer:
    "Унесите тренутну километражу возила у километрима за бољу процену његове вредности.",
  check_another_vin: "Проверите други ВИН",
  type_plate: "Унесите регистарску таблицу возила.",
  type_first_reg_date: "Унесите датум прве регистрације возила.",
  price_odometer: "Цена / километража",
  price_year: "Цена/год",
  year: "Година",
  vehicle_history_report: "Извештај о историји возила",
  click: "Кликните",
  here: "Ево",
  and_check_vehicle_history: "и проверите историју возила вашег аутомобила",
  decode_your_number: "Дешифрирајте свој број",
  for_free: "потпуно бесплатно",
  check_vehicle_valuation: "Проверите процену вашег возила на веб страници",
  stolen_cars_database: "База украдених возила",
  check_if_your_vehicle_is_in_stolen_database: "Проверите да ли возило види тражене уређаје",
  link: "линк",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "ВИН - број шасије",
  first_reg_plate: "датум прве регистрације",
  get_discount_code: "Добијте код за попуст",
  incidnets_history: "Историја инцидента",
  vehicle_odometer: "Километража возила",
  vehicle_inspection: "Преглед возила",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Проверите тачност вашег ВИН броја",
  enter_your_vin_number: "Унесите свој ВИН број",
  result_of_valid_vin_number: "Резултат исправности ВИН броја",
  entered_number: "Унети број",
  proved_to_be: "испоставило се да је",
  correct: "Тачно",
  incorrect: "Неисправан",
  entered_vin_number: "ВИН број унет",
  corrected_vin_number: "Ревидирани ВИН број",
  alternatives_vin_list_numbers: "Листа алтернативних ВИН бројева",
  check_and_clicked_interested_vin_number: "",
  check: "Проверавати",
  bg: "Бугарски",
  ar: "Арапски",
  cs: "Чешки",
  da: "Дански",
  de: "Немачки",
  el: "Грчки",
  en: "Британци",
  es: "Шпански",
  fr: "Француски",
  hu: "Мађарски",
  it: "Италијан",
  lt: "Литвански",
  nl: "Холандски",
  no: "Норвешки",
  pl: "Пољски",
  pt: "Португалски",
  ro: "Румунски",
  ru: "Руски",
  sk: "Словачки",
  sr: "Српски",
  sv: "Шведски",
  tr: "Турски",
  uk: "Украјински",
};
