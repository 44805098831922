export const cs = {
  car_valuation: "OCENĚNÍ VOZIDLA",
  toggle_navigation: "Přepnout navigaci",
  vin: "VIN",
  plate: "Stůl",
  mileage: "Počet najetých kilometrů",
  first_reg_date: "datum první registrace",
  check_valuation: "Zkontrolujte nabídku",
  contact: "Kontakt",
  privacy_policy: "Zásady ochrany osobních údajů",
  regulations: "Statut",
  valuation: "Ocenění",
  min_price: "Minimální cena",
  med_price: "Průměrná cena",
  max_price: "Maximální cena",
  we_have_report_for_you: "Máme report pro vaše vozidlo!",
  incident_history: "Historie incidentů",
  odometer_reading: "Odečítání kilometrů",
  vehicles_inspection: "Prohlídky vozidel",
  archive_photos: "Archivní fotky",
  check_now: "ZKONTROLOVAT NYNÍ!",
  unfortunately_not_found_desc:
    "Bohužel jsme nenašli rok výroby tohoto vozidla, zadejte jej prosím do pole výše.",
  type_odometer:
    "Pro lepší odhad jeho hodnoty zadejte aktuální stav najetých kilometrů vozidla v kilometrech.",
  check_another_vin: "Zkontrolujte jiný VIN",
  type_plate: "Zadejte SPZ vozidla.",
  type_first_reg_date: "Zadejte datum první registrace vozidla.",
  price_odometer: "Cena / ujeté kilometry",
  price_year: "Cena / rok",
  year: "Rok",
  vehicle_history_report: "Zpráva o historii vozidla",
  click: "Klikněte",
  here: "Tady",
  and_check_vehicle_history: "a zkontrolujte historii vozidla",
  decode_your_number: "Dekódujte své číslo",
  for_free: "zcela zdarma",
  check_vehicle_valuation: "Ověřte si ocenění svého vozu na webu",
  stolen_cars_database: "Databáze odcizených vozidel",
  check_if_your_vehicle_is_in_stolen_database: "Zkontrolujte, zda vozidlo vidí požadovaná zařízení",
  link: "odkaz",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - číslo podvozku",
  first_reg_plate: "datum první registrace",
  get_discount_code: "Získejte slevový kód",
  incidnets_history: "Historie incidentů",
  vehicle_odometer: "Počet najetých kilometrů vozidla",
  vehicle_inspection: "Kontrola vozidla",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Zkontrolujte prosím správnost vašeho čísla VIN",
  enter_your_vin_number: "Zadejte své VIN číslo",
  result_of_valid_vin_number: "Výsledek správnosti čísla VIN",
  entered_number: "Zadané číslo",
  proved_to_be: "ukázal se být",
  correct: "Opravit",
  incorrect: "Nesprávný",
  entered_vin_number: "zadáno VIN číslo",
  corrected_vin_number: "Upravené VIN číslo",
  alternatives_vin_list_numbers: "Alternativní seznam VIN čísel",
  check_and_clicked_interested_vin_number: "",
  check: "Šek",
  bg: "Bulharský",
  ar: "Arabština",
  cs: "Čeština",
  da: "Dánština",
  de: "Němec",
  el: "Řecký",
  en: "Britský",
  es: "Španělština",
  fr: "Francouzština",
  hu: "Maďarský",
  it: "Italština",
  lt: "Litevský",
  nl: "Holandský",
  no: "Norský",
  pl: "Polština",
  pt: "Portugalština",
  ro: "Rumunština",
  ru: "Ruština",
  sk: "Slovák",
  sr: "Srbština",
  sv: "Švédský",
  tr: "Turečtina",
  uk: "Ukrajinština",
};
