<template>
  <nav
    class="
      navbar navbar-light navbar-expand
      bg-light
      d-flex d-xl-flex
      navigation-clean
    "
  >
    <div class="container">
      <h3
        class="
          d-xl-flex
          justify-content-center
          align-items-center align-content-center align-items-xl-center
        "
        style="color: var(--bs-danger)"
      >
        <router-link class="text-decoration-none text-danger" to="/"><img src="../../public/assets/img/logo.svg" width="180" height="180" class="img-fluid" alt="VINCorrect"></router-link>
      </h3>

      <div class="d-flex align-items-center">
        <Langs class="me-5" />
        <p
          class="text-danger"
          style="
            margin: 0px;
            font-size: 12px;
            font-style: italic;
            font-weight: bold;
          "
        >
          Powered by Car Data Experts
        </p>
      </div>
    </div>
  </nav>
</template>

<script>
import Langs from "./Langs";

export default {
  name: "Nav",
  components: {
    Langs,
  }
};
</script>

<style scoped>
nav {
  background-color: #efefef !important;
}
</style>