<template>
  <div class="dropdown">
    <button
      class="btn dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img
        :src="require(`../../public/assets/img/languages/${langActive(activeLang)}.png`)"
        :alt="lang"
      />
      <span class="ms-1">{{ t(getNameByCountryCode(activeLang)) }}</span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(country, index) in countries" :key="index" @click="setLang(country)">
        <span class="dropdown-item"
          ><img
            :src="require(`../../public/assets/img/languages/${country}.png`)"
            :alt="country"
        /> {{ t(getNameByCountryCode(country)) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { getCountryCode } from "../utils/config";
import { getNameByCountryCode } from '../utils/config';
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "Langs",
  setup() {
    let activeLang = ref(navigator.language);
    const { locale, t } = useI18n();
    const store = useStore();
    const countryName = ref("");

    const countries = {
      pl: "pl",
      en: "en",
      de: "de",
      cs: "cs",
      lt: "lt",
      fr: "fr",
      es: "es",
      ro: "ro",
      // ru: "ru",
      nl: "nl",
      hu: "hu",
      bg: "bg",
      da: "da",
      el: "el",
      it: "it",
      no: "no",
      pt: "pt",
      sk: "sk",
      sr: "sr",
      sv: "sv",
      tr: "tr",
      uk: "uk",
      ar: "ar",
    };
    
    const setLang = (lang) => {
      activeLang.value = lang;
      locale.value = lang;
      store.commit("updateLang", lang);
      store.commit("updateCountryCode", getCountryCode(lang));
    };


    const langActive = (locale) => {
      const index = locale.indexOf("-");
      let result = null;

      if (index < 0) {
        result = locale;
      } else {
        result = locale.slice(0, index);
      }

      countryName.value = getNameByCountryCode(result);

      return result;
    };

    onMounted(async () => {
      await store.dispatch("SET_LANG");
      const lang = store.state.lang;
      activeLang.value = lang;
    });


    return {
      countries,
      setLang,
      activeLang,
      langActive,
      countryName,
      getNameByCountryCode,
      t,
    };
  },
};
</script>

<style>
</style>