<template>
  <div>
    <Nav />
    <Header />

    <main>
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </main>

    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
import Nav from './components/Nav';
import Header from './components/Header';
// import FastPrecheck from './components/FastPrecheck';
import Footer from './components/Footer';
import { computed, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    Nav,
    Header,
    Footer,
    // FastPrecheck,
  },
  setup() {
    const store = useStore();
    const showFastPrecheck = ref(false);

    const result = computed(() => {
      return store.state.result
    });

    const valid = computed(() => {
      return store.state.validStatus;
    });

    return {
      result,
      valid,
      showFastPrecheck
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.router-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
