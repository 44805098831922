export const ro = {
  car_valuation: "EVALUARE VEHICULE",
  toggle_navigation: "Comutare navigare",
  vin: "VIN",
  plate: "Masa",
  mileage: "Kilometraj",
  first_reg_date: "data primei înmatriculări",
  check_valuation: "Verificați oferta",
  contact: "A lua legatura",
  privacy_policy: "Politica de Confidențialitate",
  regulations: "Statut",
  valuation: "Evaluare",
  min_price: "Pret minim",
  med_price: "Prețul mediu",
  max_price: "Pret maxim",
  we_have_report_for_you: "Avem un raport pentru vehiculul dumneavoastră!",
  incident_history: "Istoricul incidentelor",
  odometer_reading: "Citirea kilometrajului",
  vehicles_inspection: "Inspecții de vehicule",
  archive_photos: "Fotografii de arhivă",
  check_now: "VERIFICA ACUM!",
  unfortunately_not_found_desc:
    "Din păcate, nu am găsit anul de producție pentru acest vehicul, vă rugăm să îl introduceți în câmpul de mai sus.",
  type_odometer:
    "Introduceți kilometrajul curent al vehiculului în kilometri pentru o estimare mai bună a valorii acestuia.",
  check_another_vin: "Verificați un alt VIN",
  type_plate: "Introduceți plăcuța de înmatriculare a vehiculului.",
  type_first_reg_date: "Introduceți data primei înmatriculări a vehiculului.",
  price_odometer: "Pret/kilometraj",
  price_year: "Pret/an",
  year: "An",
  vehicle_history_report: "Raportul istoric al vehiculului",
  click: "Clic",
  here: "Aici",
  and_check_vehicle_history: "și verificați istoricul vehiculului mașinii dvs",
  decode_your_number: "Decodați numărul dvs",
  for_free: "complet gratuit",
  check_vehicle_valuation: "Verificați evaluarea vehiculului dvs. pe site",
  stolen_cars_database: "Baza de date cu vehicule furate",
  check_if_your_vehicle_is_in_stolen_database: "Verificați dacă vehiculul vede dispozitivele dorite",
  link: "legătură",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - numărul de șasiu",
  first_reg_plate: "data primei înmatriculări",
  get_discount_code: "Obțineți un cod de reducere",
  incidnets_history: "Istoricul incidentelor",
  vehicle_odometer: "Kilometrajul vehiculului",
  vehicle_inspection: "Inspecția vehiculului",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number:
    "Vă rugăm să verificați corectitudinea numărului dvs. VIN",
  enter_your_vin_number: "Introduceți numărul dvs. VIN",
  result_of_valid_vin_number: "Rezultatul corectitudinii numărului VIN",
  entered_number: "Număr introdus",
  proved_to_be: "s-a dovedit a fi",
  correct: "Corect",
  incorrect: "Incorect",
  entered_vin_number: "Numărul VIN introdus",
  corrected_vin_number: "Număr VIN revizuit",
  alternatives_vin_list_numbers: "Lista de numere VIN alternative",
  check_and_clicked_interested_vin_number: "",
  check: "Verifica",
  bg: "Bulgară",
  ar: "Arabic",
  cs: "Ceh",
  da: "Danez",
  de: "Limba germana",
  el: "Greacă",
  en: "Britanic",
  es: "Spaniolă",
  fr: "Limba franceza",
  hu: "Maghiară",
  it: "Italiană",
  lt: "Lituanian",
  nl: "Olandeză",
  no: "Norvegian",
  pl: "Lustrui",
  pt: "Portugheză",
  ro: "Română",
  ru: "Rusă",
  sk: "Slovacă",
  sr: "Sârb",
  sv: "Suedez",
  tr: "Turc",
  uk: "Ucrainean",
};
