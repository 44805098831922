export const ar = {
  car_valuation: "تقييم السيارة",
  toggle_navigation: "تبديل التنقل",
  vin: "فين",
  plate: "الطاولة",
  mileage: "عدد الأميال",
  first_reg_date: "تاريخ أول تسجيل",
  check_valuation: "تحقق من الاقتباس",
  contact: "اتصل",
  privacy_policy: "سياسة الخصوصية",
  regulations: "النظام الأساسي",
  valuation: "تقييم",
  min_price: "أدنى سعر",
  med_price: "متوسط السعر",
  max_price: "السعر الأقصى",
  we_have_report_for_you: "لدينا تقرير عن سيارتك!",
  incident_history: "تاريخ الحادثة",
  odometer_reading: "قراءة الأميال",
  vehicles_inspection: "فحص المركبات",
  archive_photos: "صور أرشيفية",
  check_now: "تأكد الان!",
  unfortunately_not_found_desc:
    "للأسف ، لم نعثر على سنة الإنتاج لهذه السيارة ، يرجى إدخالها في الحقل أعلاه.",
  type_odometer:
    "أدخل الأميال الحالية للمركبة بالكيلومترات للحصول على تقدير أفضل لقيمتها.",
  check_another_vin: "تحقق من رقم تعريف شخصي مختلف",
  type_plate: "أدخل لوحة ترخيص السيارة.",
  type_first_reg_date: "أدخل تاريخ التسجيل الأول للسيارة.",
  price_odometer: "السعر / الأميال",
  price_year: "السعر / السنة",
  year: "سنة",
  vehicle_history_report: "تقرير تاريخ السيارة",
  click: "انقر",
  here: "هنا",
  and_check_vehicle_history: "وتحقق من سجل مركبة سيارتك",
  decode_your_number: "فك شفرة رقمك",
  for_free: "مجاني تماما",
  check_vehicle_valuation: "تحقق من تقييم سيارتك على الموقع",
  stolen_cars_database: "قاعدة بيانات للمركبات المسروقة",
  check_if_your_vehicle_is_in_stolen_database: "تحقق مما إذا كانت السيارة ترى الأجهزة المطلوبة",
  link: "حلقة الوصل",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - رقم الشاسيه",
  first_reg_plate: "تاريخ أول تسجيل",
  get_discount_code: "احصل على كود الخصم",
  incidnets_history: "تاريخ الحادثة",
  vehicle_odometer: "المسافة المقطوعة بالسيارة",
  vehicle_inspection: "فحص المركبة",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "يرجى التحقق من صحة رقم VIN الخاص بك",
  enter_your_vin_number: "أدخل رقم VIN الخاص بك",
  result_of_valid_vin_number: "نتيجة صحة رقم VIN",
  entered_number: "الرقم المدخل",
  proved_to_be: "اتضح أن يكون",
  correct: "صحيح",
  incorrect: "غير صحيح",
  entered_vin_number: "تم إدخال رقم VIN",
  corrected_vin_number: "رقم VIN المنقح",
  alternatives_vin_list_numbers: "قائمة أرقام VIN البديلة",
  check_and_clicked_interested_vin_number: "",
  check: "يفحص",
  bg: "البلغارية",
  ar: "عربي",
  cs: "التشيكية",
  da: "دانماركي",
  de: "ألمانية",
  el: "اليونانية",
  en: "بريطاني",
  es: "الأسبانية",
  fr: "فرنسي",
  hu: "المجرية",
  it: "إيطالي",
  lt: "الليتوانية",
  nl: "هولندي",
  no: "النرويجية",
  pl: "تلميع",
  pt: "البرتغالية",
  ro: "روماني",
  ru: "الروسية",
  sk: "السلوفاكية",
  sr: "الصربية",
  sv: "السويدية",
  tr: "اللغة التركية",
  uk: "الأوكرانية",
};
