<template>
  <div>
    <section id="result" v-if="valid != null">
      <ValidResult :valid="valid" :precheck="precheck" />
    </section>

    <section v-if="result != null && Object.keys(result) != 0">
      <VinResults :valid="valid" :result="result" />
    </section>

    <section class="text-center bg-light features-icons mt-5 pt-5">
      <CardsSection />
    </section>
  </div>
</template>
  
<script>
import CardsSection from "../components/CardsSection/CardsSection";
import ValidResult from "../components/ValidResult";
import VinResults from "../components/VinResults";

import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { watchEffect, nextTick } from 'vue';

export default {
  name: "Home",
  components: {
    CardsSection,
    ValidResult,
    VinResults,
  },
  setup() {
    const store = useStore();

    const valid = computed(() => {
      return store.state.validStatus;
    });

    const precheck = computed(() => {
      return store.state.precheck;
    });

    const vin = computed(() => {
      return store.state.vin;
    });

    const result = computed(() => {
      return store.state.result;
    });

    const scrollToSection = (element) => {
      console.log(element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    watchEffect(() => {
      if (valid.value !== null) {
        nextTick(() => {
          const element = document.getElementById('result');
          if (element) {
            scrollToSection(element);
          }
        });
      }
    });

    return {
      result,
      valid,
      precheck,
      vin,
    };
  },
};
</script>

<style></style>