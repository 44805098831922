<template>
  <header class="text-center text-white masthead">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 mx-auto position-relative">
          <h2 class="mb-5">{{ t('check_your_vin_number') }}</h2>
        </div>
        <div class="col-md-10 col-lg-8 col-xl-7 mx-auto position-relative">
          <form>
            <div class="row">
              <div class="col-12 col-md-9 mb-2 mb-md-0">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  :placeholder="t('enter_your_vin_number')"
                  maxlength="17"
                  minlength="17"
                  required=""
                  style="--bs-danger: #dc3545; --bs-danger-rgb: 220, 53, 69"
                  v-model="vin"
                />
              </div>
              <div class="col-12 col-md-3">
                <vue-recaptcha
                  theme="light"
                  size="invisible"
                  :tabindex="0"
                  @widgetId="recaptchaWidget = $event"
                  @verify="callbackVerify($event)"
                  @expired="callbackExpired()"
                  @fail="callbackFail()"
                />
                <button
                  @click="checkValuationButton"
                  :disabled="vin.length != 17 || loading"
                  class="btn btn-danger btn-lg tada animated"
                  type="button"
                  style="
                    color: var(--bs-white);
                    border-width: 0px;
                    border-style: none;
                    min-width: 77px !important;
                  "
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-else>{{ t('check') }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

export default {
  name: "Header",
  components: {
    VueRecaptcha,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const router = useRouter();

    const vin = ref("");
    const loading = computed(() => {
      return store.state.loading;
    });

    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    const sendForm = async (vin, token) => {
      store.commit("updateLoading", true);
      if (router.currentRoute.value.name != 'home') {
        await router.push('/');
      }

      const response = store.dispatch("sendRequest", { vin, token });
      if (response == "captcha_failed") {
        store.commit("updateLoading", false);
        actionReset();
        window.grecaptcha.execute();
        return;
      }
    };

    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(null);

    const callbackVerify = (response) => {
      store.commit("updateToken", response);
      sendForm(vin.value, response);
    };
    const callbackExpired = () => {
      store.commit("updateToken", null);
    };
    const callbackFail = () => {
      console.log("fail");
    };

    const checkValuationButton = () => {
      if(store.state.token) {
      sendForm(vin.value, store.state.token);
      } else {
        window.grecaptcha.execute();
      }
    };

    return {
      vin,
      loading,
      sendForm,
      callbackVerify,
      callbackExpired,
      callbackFail,
      checkValuationButton,
      t,
    };
  },
};
</script>

<style scoped>
header {
  background: linear-gradient(
      -168deg,
      black 0%,
      rgba(24, 10, 21, 0.540951954475988) 60%,
      rgba(33, 13, 29, 0.37131280427139635) 84%,
      rgba(35, 14, 31, 0.33) 89%,
      white 100%,
      rgba(24, 10, 21, 0.5408692431164341) 100%
    ),
    url("../../public/assets/img/background.jpg") center center / cover
      no-repeat;
  border-style: none;
  box-shadow: 0px 0px 9px 0px var(--bs-gray-700);
}

button {
  border: none !important;
  outline: 0 !important;
}

button:focus {
  border: none !important;
  outline: 0 !important;
}
</style>