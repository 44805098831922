export const lt = {
  car_valuation: "TRANSPORTO PRIEMONĖS ĮVERTINIMAS",
  toggle_navigation: "Perjungti navigaciją",
  vin: "VIN",
  plate: "Lentelė",
  mileage: "Rida",
  first_reg_date: "pirmosios registracijos data",
  check_valuation: "Patikrinkite citatą",
  contact: "Kontaktas",
  privacy_policy: "Privatumo politika",
  regulations: "Statutas",
  valuation: "Vertinimas",
  min_price: "Minimali kaina",
  med_price: "Vidutinė kaina",
  max_price: "Maksimali kaina",
  we_have_report_for_you: "Turime ataskaitą apie jūsų automobilį!",
  incident_history: "Incidento istorija",
  odometer_reading: "Kilometražo skaitymas",
  vehicles_inspection: "Transporto priemonių patikros",
  archive_photos: "Archyvinės nuotraukos",
  check_now: "PATIKRINK DABAR!",
  unfortunately_not_found_desc:
    "Deja, šios transporto priemonės gamybos metų neradome, prašome juos įvesti į aukščiau esantį laukelį.",
  type_odometer:
    "Įveskite esamą transporto priemonės ridą kilometrais, kad galėtumėte geriau įvertinti jos vertę.",
  check_another_vin: "Patikrinkite kitą VIN",
  type_plate: "Įveskite transporto priemonės valstybinį numerį.",
  type_first_reg_date:
    "Įveskite transporto priemonės pirmosios registracijos datą.",
  price_odometer: "Kaina / rida",
  price_year: "Kaina/metai",
  year: "Metai",
  vehicle_history_report: "Transporto priemonės istorijos ataskaita",
  click: "Spustelėkite",
  here: "Čia",
  and_check_vehicle_history:
    "ir patikrinkite savo automobilio automobilio istoriją",
  decode_your_number: "Iššifruokite savo numerį",
  for_free: "visiškai nemokama",
  check_vehicle_valuation: "Patikrinkite savo automobilio vertę svetainėje",
  stolen_cars_database: "Vogtų transporto priemonių duomenų bazė",
  check_if_your_vehicle_is_in_stolen_database: "Patikrinkite, ar transporto priemonėje matomi ieškomi įrenginiai",
  link: "nuoroda",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN – važiuoklės numeris",
  first_reg_plate: "pirmosios registracijos data",
  get_discount_code: "Gaukite nuolaidos kodą",
  incidnets_history: "Incidento istorija",
  vehicle_odometer: "Transporto priemonės rida",
  vehicle_inspection: "Transporto priemonės apžiūra",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Patikrinkite VIN numerio teisingumą",
  enter_your_vin_number: "Įveskite savo VIN numerį",
  result_of_valid_vin_number: "VIN numerio teisingumo rezultatas",
  entered_number: "Įvestas numeris",
  proved_to_be: "jis pasirodė esąs",
  correct: "Teisingai",
  incorrect: "Neteisinga",
  entered_vin_number: "Įvestas VIN numeris",
  corrected_vin_number: "Patikslintas VIN numeris",
  alternatives_vin_list_numbers: "Alternatyvus VIN numerių sąrašas",
  check_and_clicked_interested_vin_number: "",
  check: "Patikrinti",
  bg: "Bulgarų",
  ar: "Arabiškas",
  cs: "Čekų",
  da: "Danų",
  de: "Vokiečių",
  el: "Graikų",
  en: "Britų",
  es: "Ispanų",
  fr: "Prancūzų kalba",
  hu: "Vengrų",
  it: "Italų",
  lt: "Lietuvių",
  nl: "Olandų",
  no: "Norvegų",
  pl: "Lenkas",
  pt: "Portugalų",
  ro: "Rumunų",
  ru: "Rusų",
  sk: "Slovakų",
  sr: "Serbų",
  sv: "Švedijos",
  tr: "Turkų",
  uk: "Ukrainiečių",
};
