export const el = {
  car_valuation: "ΑΞΙΟΛΟΓΗΣΗ ΟΧΗΜΑΤΟΣ",
  toggle_navigation: "Εναλλαγή πλοήγησης",
  vin: "VIN",
  plate: "Τραπέζι",
  mileage: "Απόσταση σε μίλια",
  first_reg_date: "ημερομηνία πρώτης εγγραφής",
  check_valuation: "Ελέγξτε το απόσπασμα",
  contact: "Επικοινωνία",
  privacy_policy: "πολιτική απορρήτου",
  regulations: "Νόμος",
  valuation: "Εκτίμηση",
  min_price: "Ελάχιστη τιμή",
  med_price: "Μέση τιμή",
  max_price: "Μέγιστη τιμή",
  we_have_report_for_you: "Έχουμε αναφορά για το όχημά σας!",
  incident_history: "Ιστορικό περιστατικού",
  odometer_reading: "Ανάγνωση χιλιομέτρων",
  vehicles_inspection: "Έλεγχοι οχημάτων",
  archive_photos: "Αρχειακές φωτογραφίες",
  check_now: "ΕΛΕΓΞΤΕ ΤΩΡΑ!",
  unfortunately_not_found_desc:
    "Δυστυχώς, δεν βρήκαμε το έτος παραγωγής για αυτό το όχημα, εισαγάγετε το στο παραπάνω πεδίο.",
  type_odometer:
    "Εισαγάγετε την τρέχουσα χιλιομετρική απόσταση του οχήματος σε χιλιόμετρα για καλύτερη εκτίμηση της αξίας του.",
  check_another_vin: "Ελέγξτε ένα διαφορετικό VIN",
  type_plate: "Εισαγάγετε την πινακίδα κυκλοφορίας του οχήματος.",
  type_first_reg_date:
    "Εισαγάγετε την ημερομηνία της πρώτης ταξινόμησης του οχήματος.",
  price_odometer: "Τιμή / χιλιόμετρα",
  price_year: "Τιμή / έτος",
  year: "Ετος",
  vehicle_history_report: "Αναφορά Ιστορικού Οχήματος",
  click: "Κάντε κλικ",
  here: "Εδώ",
  and_check_vehicle_history: "και ελέγξτε το ιστορικό του αυτοκινήτου σας",
  decode_your_number: "Αποκωδικοποιήστε τον αριθμό σας",
  for_free: "εντελώς δωρεάν",
  check_vehicle_valuation:
    "Ελέγξτε την εκτίμηση του οχήματός σας στον ιστότοπο",
  stolen_cars_database: "Βάση δεδομένων κλεμμένων οχημάτων",
  check_if_your_vehicle_is_in_stolen_database: "Ελέγξτε εάν το όχημα βλέπει συσκευές που αναζητούνται",
  link: "Σύνδεσμος",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - αριθμός πλαισίου",
  first_reg_plate: "ημερομηνία πρώτης εγγραφής",
  get_discount_code: "Λάβετε έναν εκπτωτικό κωδικό",
  incidnets_history: "Ιστορικό περιστατικού",
  vehicle_odometer: "Χιλιόμετρα οχήματος",
  vehicle_inspection: "Έλεγχος οχήματος",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Ελέγξτε την ορθότητα του αριθμού VIN σας",
  enter_your_vin_number: "Εισαγάγετε τον αριθμό VIN σας",
  result_of_valid_vin_number: "Το αποτέλεσμα της ορθότητας του αριθμού VIN",
  entered_number: "Εισάχθηκε ο αριθμός",
  proved_to_be: "αποδείχθηκε ότι ήταν",
  correct: "Σωστός",
  incorrect: "Ανακριβής",
  entered_vin_number: "Εισάχθηκε ο αριθμός VIN",
  corrected_vin_number: "Αναθεωρημένος αριθμός VIN",
  alternatives_vin_list_numbers: "Εναλλακτικοί αριθμοί VIN",
  check_and_clicked_interested_vin_number: "",
  check: "Ελεγχος",
  bg: "Βούλγαρος",
  ar: "Αραβικός",
  cs: "Τσέχος",
  da: "Δανικός",
  de: "Γερμανός",
  el: "Ελληνικά",
  en: "Βρετανοί",
  es: "Ισπανικά",
  fr: "Γαλλική γλώσσα",
  hu: "Ουγγρικός",
  it: "Ιταλικός",
  lt: "Λιθουανικά",
  nl: "Ολλανδός",
  no: "Νορβηγός",
  pl: "Στίλβωση",
  pt: "Πορτογαλικά",
  ro: "Ρουμανικός",
  ru: "Ρωσική",
  sk: "Σλοβάκος",
  sr: "Σέρβος",
  sv: "Σουηδικά",
  tr: "Τούρκικος",
  uk: "Ουκρανός",
};
