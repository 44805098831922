<template>
  <div class="container mt-5">
    <router-link to="/" class="text-danger">{{ t("back") }}</router-link>
    <h4 class="mt-4">{{ t("admission") }}</h4>

    <p
      v-html="t('regulations_admission', { email: 'info@cardataexperts.com' })"
    ></p>

    <h4>{{ t("definition") }}</h4>

    <p v-html="t('regulations_definition')"></p>

    <h4>{{ t("license_use_page") }}</h4>

    <p v-html="t('regulations_license_desc')"></p>

    <h4>{{ t("use_page") }}</h4>

    <p v-html="t('regulations_use_page')"></p>

    <h4>{{ t("limited_access") }}</h4>

    <p v-html="t('regulations_limited_access')"></p>

    <h4>{{ t("user_posted_content") }}</h4>

    <p v-html="t('regulations_user_posted')"></p>

    <h4>{{ t("placing_vehicle_data_in_db") }}</h4>

    <p v-html="t('regulations_placing_data_in_db')"></p>

    <h4>{{ t("limited_and_warranties_complaints") }}</h4>

    <p v-html="t('regulations_limited_and_warranties_complaints')"></p>

    <h4>{{ t("disclaimer_of_liability") }}</h4>

    <p v-html="t('regulations_liability')"></p>

    <h4>{{ t("relief_from_obligations") }}</h4>

    <p v-html="t('regulations_relief')"></p>

    <h4>{{ t("violations_of_regulations") }}</h4>

    <p v-html="t('reulations_violations')"></p>

    <h4>{{ t("changing_of_provisions_regulations") }}</h4>

    <p v-html="t('regulations_changing_provisions_regulations')"></p>

    <h4>{{ t("transfer") }}</h4>

    <p v-html="t('regulations_transfer')"></p>

    <h4>{{ t("severability_clause") }}</h4>

    <p v-html="t('regulations_severability_clause')"></p>

    <h4>{{ t("exclusion_third_parts") }}</h4>

    <p v-html="t('regulations_exclusion_parts')"></p>

    <h4>{{ t("scope_of_contract") }}</h4>

    <p v-html="t('regulations_scope_contract')"></p>

    <h4>{{ t("governing_law_and_jurisdiction") }}</h4>

    <p v-html="t('regulations_governing_law')"></p>
  </div>
</template>
  
  <script>
import { useI18n } from "vue-i18n";

export default {
  name: "Regulations",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>
  
<style scoped>
p {
  font-size: 14px;
  /* margin-right: 30px; */
}

a:hover {
  text-decoration: underline;
}
</style>