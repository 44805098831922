export const de = {
  car_valuation: "FAHRZEUGWERTUNG",
  toggle_navigation: "Navigation umschalten",
  vin: "Fahrgestellnummer",
  plate: "Tisch",
  mileage: "Kilometerstand",
  first_reg_date: "Datum der Erstzulassung",
  check_valuation: "Überprüfen Sie das Angebot",
  contact: "Kontakt",
  privacy_policy: "Datenschutz-Bestimmungen",
  regulations: "Satzung",
  valuation: "Bewertung",
  min_price: "Minimaler Preis",
  med_price: "Durchschnittlicher Preis",
  max_price: "Höchstpreis",
  we_have_report_for_you: "Wir haben ein Gutachten für Ihr Fahrzeug!",
  incident_history: "Vorfallgeschichte",
  odometer_reading: "Kilometerstand lesen",
  vehicles_inspection: "Fahrzeuginspektionen",
  archive_photos: "Archivfotos",
  check_now: "JETZT PRÜFEN!",
  unfortunately_not_found_desc:
    "Leider haben wir für dieses Fahrzeug kein Baujahr gefunden, bitte tragen Sie es oben in das Feld ein.",
  type_odometer:
    "Geben Sie den aktuellen Kilometerstand des Fahrzeugs in Kilometern ein, um den Wert besser einschätzen zu können.",
  check_another_vin: "Überprüfen Sie eine andere Fahrgestellnummer",
  type_plate: "Geben Sie das Kennzeichen des Fahrzeugs ein.",
  type_first_reg_date:
    "Geben Sie das Datum der Erstzulassung des Fahrzeugs ein.",
  price_odometer: "Preis / Kilometer",
  price_year: "Preis / Jahr",
  year: "Jahr",
  vehicle_history_report: "Bericht zur Fahrzeughistorie",
  click: "Klicken",
  here: "Hier",
  and_check_vehicle_history:
    "und überprüfen Sie die Fahrzeughistorie Ihres Autos",
  decode_your_number: "Entschlüsseln Sie Ihre Nummer",
  for_free: "komplett frei",
  check_vehicle_valuation:
    "Überprüfen Sie die Bewertung Ihres Fahrzeugs auf der Website",
  stolen_cars_database: "Datenbank gestohlener Fahrzeuge",
  check_if_your_vehicle_is_in_stolen_database: "Überprüfen Sie, ob das Fahrzeug gesuchte Geräte sieht",
  link: "Verknüpfung",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - Fahrgestellnummer",
  first_reg_plate: "Datum der Erstzulassung",
  get_discount_code: "Erhalten Sie einen Rabattcode",
  incidnets_history: "Vorfallgeschichte",
  vehicle_odometer: "Kilometerstand des Fahrzeugs",
  vehicle_inspection: "Fahrzeuginspektion",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number:
    "Bitte überprüfen Sie die Korrektheit Ihrer Fahrgestellnummer",
  enter_your_vin_number: "Geben Sie Ihre Fahrgestellnummer ein",
  result_of_valid_vin_number:
    "Das Ergebnis der Richtigkeit der Fahrgestellnummer",
  entered_number: "Eingegebene Nummer",
  proved_to_be: "er stellte sich heraus",
  correct: "Richtig",
  incorrect: "Falsch",
  entered_vin_number: "Fahrgestellnummer eingegeben",
  corrected_vin_number: "Überarbeitete Fahrgestellnummer",
  alternatives_vin_list_numbers: "Alternative VIN-Nummernliste",
  check_and_clicked_interested_vin_number: "",
  check: "Prüfen",
  bg: "Bulgarisch",
  ar: "Arabisch",
  cs: "Tschechisch",
  da: "Dänisch",
  de: "Deutsch",
  el: "Griechisch",
  en: "Britisch",
  es: "Spanisch",
  fr: "Französisch",
  hu: "Ungarisch",
  it: "Italienisch",
  lt: "Litauisch",
  nl: "Niederländisch",
  no: "Norwegisch",
  pl: "Polieren",
  pt: "Portugiesisch",
  ro: "Rumänisch",
  ru: "Russisch",
  sk: "Slowakisch",
  sr: "Serbisch",
  sv: "Schwedisch",
  tr: "Türkisch",
  uk: "Ukrainisch",
};
