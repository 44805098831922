import { createStore } from "vuex";
import { defaultState } from "../utils/config";
import { useI18n } from 'vue-i18n';
import reqStartUp from "../composables/reqStartUp";

export default createStore({
  state() {
    return defaultState();
  },
  getters: {},
  mutations: {
    updateResult(state, payload) {
      state.result = payload;
    },
    updatePrecheck(state, payload) {
      state.precheck = payload;
    },
    updateVin(state, payload) {
      state.vin = payload;
    },
    updateValidStatus(state, payload) {
      state.validStatus = payload;
    },
    updateCountryCode(state, payload) {
      state.regCountry = payload;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
    updateToken(state, payload) {
      state.token = payload;
    },
    updateLang(state, payload) {
      state.lang = payload;
    },
    resetState(state) {
      for (const singleData in state) {
        state[singleData] = defaultState()[singleData];
      }
    },
  },
  actions: {
    async sendRequest({ commit }, payload) {
      const { sendReqStartUp, response } = reqStartUp();
      const send = await sendReqStartUp(payload.vin, payload.token);

      response.value.data.result.vin = payload.vin;

      if (send) {
        if (response.value.data.message == "captcha_failed") {
          return "captcha_failed";
        }
        try {
          commit("updateValidStatus", response.value.data.result.valid);
          commit("updateResult", response.value.data.result.vin_info);
          commit("updatePrecheck", response.value.data.result.precheck);
          commit("updateVin", response.value.data.result.vin);
          commit("updateLoading", false);
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
    },
    SET_LANG(context) {
      const { locale } = useI18n();
      const index = locale.value.indexOf("-");
      let result = null;

      if (index < 0) {
        result = locale.value;
      } else {
        result = locale.value.slice(0, index);
      }
      context.commit("updateLang", result);
    },
    resetData({ commit }) {
      commit("resetState");
    },
  },
});
