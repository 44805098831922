export const it = {
  car_valuation: "VALUTAZIONE DEL VEICOLO",
  toggle_navigation: "Attiva/disattiva la navigazione",
  vin: "VIN",
  plate: "Tavolo",
  mileage: "Chilometraggio",
  first_reg_date: "data di prima immatricolazione",
  check_valuation: "Controlla il preventivo",
  contact: "Contatto",
  privacy_policy: "politica sulla riservatezza",
  regulations: "Statuto",
  valuation: "Valutazione",
  min_price: "Prezzo minimo",
  med_price: "Prezzo medio",
  max_price: "Prezzo massimo",
  we_have_report_for_you: "Abbiamo un rapporto per il tuo veicolo!",
  incident_history: "Storia dell'incidente",
  odometer_reading: "Lettura del chilometraggio",
  vehicles_inspection: "Ispezioni del veicolo",
  archive_photos: "Foto d'archivio",
  check_now: "CONTROLLA ORA!",
  unfortunately_not_found_desc:
    "Sfortunatamente, non abbiamo trovato l'anno di produzione per questo veicolo, inseriscilo nel campo sopra.",
  type_odometer:
    "Immettere il chilometraggio attuale del veicolo in chilometri per una migliore stima del suo valore.",
  check_another_vin: "Controlla un VIN diverso",
  type_plate: "Inserisci la targa del veicolo.",
  type_first_reg_date:
    "Inserire la data della prima immatricolazione del veicolo.",
  price_odometer: "Prezzo / chilometraggio",
  price_year: "Prezzo/anno",
  year: "Anno",
  vehicle_history_report: "Rapporto storico del veicolo",
  click: "Clic",
  here: "Qui",
  and_check_vehicle_history:
    "e controlla lo storico del veicolo della tua auto",
  decode_your_number: "Decodifica il tuo numero",
  for_free: "completamente gratis",
  check_vehicle_valuation:
    "Controlla la valutazione del tuo veicolo sul sito web",
  stolen_cars_database: "Database dei veicoli rubati",
  check_if_your_vehicle_is_in_stolen_database: "Controlla se il veicolo vede i dispositivi desiderati",
  link: "collegamento",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - numero di telaio",
  first_reg_plate: "data di prima immatricolazione",
  get_discount_code: "Ottieni un codice sconto",
  incidnets_history: "Storia dell'incidente",
  vehicle_odometer: "Chilometraggio del veicolo",
  vehicle_inspection: "Ispezione del veicolo",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Si prega di verificare la correttezza del numero VIN",
  enter_your_vin_number: "Inserisci il tuo numero VIN",
  result_of_valid_vin_number: "Il risultato della correttezza del numero VIN",
  entered_number: "Numero inserito",
  proved_to_be: "si è rivelato essere",
  correct: "Corretta",
  incorrect: "Errato",
  entered_vin_number: "Numero VIN inserito",
  corrected_vin_number: "Numero VIN rivisto",
  alternatives_vin_list_numbers: "Elenco dei numeri VIN alternativi",
  check_and_clicked_interested_vin_number: "",
  check: "Dai un'occhiata",
  bg: "Bulgaro",
  ar: "Arabo",
  cs: "Ceco",
  da: "Danese",
  de: "Tedesco",
  el: "Greco",
  en: "Britannico",
  es: "Spagnolo",
  fr: "Francese",
  hu: "Ungherese",
  it: "Italiano",
  lt: "lituano",
  nl: "Olandese",
  no: "Norvegese",
  pl: "Polacco",
  pt: "Portoghese",
  ro: "Rumeno",
  ru: "Russo",
  sk: "Slovacco",
  sr: "Serbo",
  sv: "Svedese",
  tr: "Turco",
  uk: "Ucraino",
};
