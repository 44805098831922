<template>
  <div class="container-fluid p-4">
    <div class="row">
      <div class="col-lg-6 text-center text-lg-start my-auto h-100">
        <ul class="list-inline mb-2">
          <li class="list-inline-item">
            <router-link class="text-danger" to="/contact">{{ t('contact') }}</router-link>
          </li>
          <li class="list-inline-item">
            <router-link class="text-danger" to="/regulations">{{ t('privacy_policy') }}</router-link>
          </li>
        </ul>
        <p class="text-muted small mb-4 mb-lg-0">
          © VINCorrect 2022. Powered by Car Data Experts. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name: "Footer",
    setup() {
      const { t } = useI18n();

      return {
        t,
      }
    }
};
</script>

<style scoped>
.container-fluid {
    background-color: #efefef !important;
}
</style>