
export const getCountryCode = (lang) => {
  const langs = {
    bg: "BG",
    ar: "AR",
    cs: "CS",
    da: "DA",
    de: "DE",
    el: "EL",
    en: "GB",
    es: "ES",
    fr: "FR",
    hu: "HU",
    it: "IT",
    lt: "LT",
    nl: "NL",
    no: "NO",
    pl: "PL",
    pt: "PT",
    ro: "RO",
    ru: "RU",
    sk: "SK",
    sr: "SR",
    sv: "SV",
    tr: "TR",
    uk: "UK",
  };
  return langs[lang] ?? lang;
};

export const getNameByCountryCode = (lang) => {
  const langs = {
    bg: 'bg',
    ar: 'ar',
    cs: 'cs',
    da: 'da',
    el: 'el',
    en: 'en',
    es: 'es',
    fr: 'fr',
    hu: 'hu',
    it: 'it',
    lt: 'lt',
    nl: 'nl',
    no: 'no',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sk: 'sk',
    sr: 'sr',
    sv: 'sv',
    tr: 'tr',
    uk: 'uk',
  };
  return langs[lang] ?? lang;
};

const partnerIds = () => {
  return {
    bg: {
      id: "80000494",
      domain: "bg",
    },
    ar: {
      id: "80000494",
      domain: "en",
    },
    cs: {
      id: "80000494",
      domain: "cs",
    },
    da: {
      id: "80000494",
      domain: "da",
    },
    de: {
      id: "80000494",
      domain: "de",
    },
    el: {
      id: "80000494",
      domain: "el",
    },
    en: {
      id: "80000494",
      domain: "en",
    },
    es: {
      id: "80000494",
      domain: "es",
    },
    fr: {
      id: "80000494",
      domain: "fr",
    },
    hu: {
      id: "80000494",
      domain: "hu",
    },
    it: {
      id: "80000494",
      domain: "it",
    },
    lt: {
      id: "80000494",
      domain: "lt",
    },
    nl: {
      id: "80000494",
      domain: "nl",
    },
    no: {
      id: "80000494",
      domain: "no",
    },
    pl: {
      id: "80000494",
      domain: "pl",
    },
    pt: {
      id: "80000494",
      domain: "pt",
    },
    ro: {
      id: "80000494",
      domain: "ro",
    },
    ru: {
      id: "80000494",
      domain: "ru",
    },
    sk: {
      id: "80000494",
      domain: "sk",
    },
    sr: {
      id: "80000494",
      domain: "sr",
    },
    sv: {
      id: "80000494",
      domain: "sv",
    },
    tr: {
      id: "80000494",
      domain: "tr",
    },
    uk: {
      id: "80000494",
      domain: "uk",
    },
  };
};

export const partnerLink = (lang) => {
  const partnersId = partnerIds();
  const link = `https://www.automoli.com/${partnersId[lang].domain}/page/vinchecker/partnerid:${partnersId[lang].id}`;

  return link;
};

export const defaultState = () => {
  return {
    validStatus: null,
    result: {},
    vin: null,
    precheck: {
      odometers: 0,
      damages: 0,
      events: 0,
      photos: 0,
    },
    loading: false,
    token: null,
    lang: navigator.language,
    regCountry: getCountryCode(navigator.language),
  };
};
