export const bg = {
  car_valuation: "ОЦЕНКА НА АВТОМОБИЛИТЕ",
  toggle_navigation: "Превключване на навигацията",
  vin: "VIN номер",
  plate: "Таблица",
  mileage: "Пробег",
  first_reg_date: "дата на първа регистрация",
  check_valuation: "Проверете офертата",
  contact: "Контакт",
  privacy_policy: "политика за поверителност",
  regulations: "Устав",
  valuation: "Остойностяване",
  min_price: "Минимална цена",
  med_price: "Средна цена",
  max_price: "Максимална цена",
  we_have_report_for_you: "Имаме доклад за вашия автомобил!",
  incident_history: "История на инцидента",
  odometer_reading: "Отчитане на километража",
  vehicles_inspection: "Прегледи на МПС",
  archive_photos: "Архивни снимки",
  check_now: "ПРОВЕРИ СЕГА!",
  unfortunately_not_found_desc:
    "За съжаление, не намерихме годината на производство на този автомобил, моля, въведете я в полето по-горе.",
  type_odometer:
    "Въведете текущия пробег на автомобила в километри за по-добра оценка на стойността му.",
  check_another_vin: "Проверете друг VIN",
  type_plate: "Въведете регистрационния номер на автомобила.",
  type_first_reg_date: "Въведете датата на първата регистрация на автомобила.",
  price_odometer: "Цена / пробег",
  price_year: "Цена / година",
  year: "година",
  vehicle_history_report: "Доклад за историята на автомобила",
  click: "Кликнете",
  here: "Тук",
  and_check_vehicle_history:
    "и проверете историята на превозното средство на вашия автомобил",
  decode_your_number: "Декодирайте номера си",
  for_free: "напълно безплатно",
  check_vehicle_valuation: "Проверете оценката на вашия автомобил на уебсайта",
  stolen_cars_database: "База данни за откраднати автомобили",
  check_if_your_vehicle_is_in_stolen_database: "Проверете дали превозното средство вижда търсени устройства",
  link: "връзка",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - номер на шасито",
  first_reg_plate: "дата на първа регистрация",
  get_discount_code: "Вземете код за отстъпка",
  incidnets_history: "История на инцидента",
  vehicle_odometer: "Пробег на автомобила",
  vehicle_inspection: "Проверка на МПС",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Моля, проверете правилността на вашия VIN номер",
  enter_your_vin_number: "Въведете своя VIN номер",
  result_of_valid_vin_number: "Резултатът от коректността на VIN номера",
  entered_number: "Въведен номер",
  proved_to_be: "той се оказа",
  correct: "Правилно",
  incorrect: "Неправилно",
  entered_vin_number: "Въведен VIN номер",
  corrected_vin_number: "Ревизиран VIN номер",
  alternatives_vin_list_numbers: "Списък с алтернативни VIN номера",
  check_and_clicked_interested_vin_number: "",
  check: "Проверете",
  bg: "Български",
  ar: "Арабски",
  cs: "Чешки",
  da: "Датски",
  de: "Немски",
  el: "Гръцки",
  en: "Британски",
  es: "Испански",
  fr: "Френски",
  hu: "Унгарски",
  it: "Италиански",
  lt: "Литовски",
  nl: "Холандски",
  no: "Норвежки",
  pl: "Полски",
  pt: "Португалски",
  ro: "Румънски",
  ru: "Руски",
  sk: "Словашки",
  sr: "Сръбски",
  sv: "Шведски",
  tr: "Турски",
  uk: "Украински",
};
