<template>
  <div>
    <h1 class="text-dark mt-3">{{ t('contact') }}</h1>
    <div class="container mt-5">
      <div class="row w-100">
        <div class="col-12 mt-5" style="height: 25vh;">
          <img
            src="../../public/assets/img/mail_black.png"
            class="img-fluid"
            alt="email"
            width="470"
            height="40"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { useI18n } from 'vue-i18n';
export default {
  name: "Contact",
  setup() {
    const { t } = useI18n();

    return {
      t,
    }
  }
};
</script>
  
  <style>
</style>