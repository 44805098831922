export const tr = {
  car_valuation: "ARAÇ DEĞERLEME",
  toggle_navigation: "Gezinmeyi aç/kapat",
  vin: "şasi numarası",
  plate: "Masa",
  mileage: "kilometre",
  first_reg_date: "ilk kayıt tarihi",
  check_valuation: "Teklifi kontrol edin",
  contact: "İletişim",
  privacy_policy: "Gizlilik Politikası",
  regulations: "tüzük",
  valuation: "Değerleme",
  min_price: "Minimum fiyat",
  med_price: "Ortalama fiyat",
  max_price: "Maksimum fiyat",
  we_have_report_for_you: "Aracınız için bir raporumuz var!",
  incident_history: "Olay geçmişi",
  odometer_reading: "kilometre okuma",
  vehicles_inspection: "Araç muayeneleri",
  archive_photos: "Arşiv fotoğrafları",
  check_now: "ŞİMDİ KONTROL ET!",
  unfortunately_not_found_desc:
    "Maalesef bu aracın üretim yılını bulamadık, lütfen yukarıdaki alana giriniz.",
  type_odometer:
    "Değerini daha iyi tahmin etmek için aracın mevcut kilometresini kilometre olarak girin.",
  check_another_vin: "Farklı bir VIN kontrol edin",
  type_plate: "Aracın plakasını girin.",
  type_first_reg_date: "Aracın ilk tescil tarihini girin.",
  price_odometer: "Fiyat / kilometre",
  price_year: "Fiyat / yıl",
  year: "Yıl",
  vehicle_history_report: "Araç Geçmiş Raporu",
  click: "Tıklamak",
  here: "Buraya",
  and_check_vehicle_history: "ve aracınızın araç geçmişini kontrol edin",
  decode_your_number: "Numaranızın kodunu çözün",
  for_free: "Tamamen bedava",
  check_vehicle_valuation: "Aracınızın değerini web sitesinde kontrol edin",
  stolen_cars_database: "Çalıntı araç veri tabanı",
  check_if_your_vehicle_is_in_stolen_database: "Aracın aranan cihazları görüp görmediğini kontrol edin",
  link: "bağlantı",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - şasi numarası",
  first_reg_plate: "ilk kayıt tarihi",
  get_discount_code: "indirim kodu al",
  incidnets_history: "olay geçmişi",
  vehicle_odometer: "araç kilometre",
  vehicle_inspection: "Araç muayenesi",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Lütfen VIN numaranızın doğruluğunu kontrol edin",
  enter_your_vin_number: "VIN numaranızı girin",
  result_of_valid_vin_number: "VIN numarasının doğruluğunun sonucu",
  entered_number: "Girilen numara",
  proved_to_be: "o olduğu ortaya çıktı",
  correct: "Doğru",
  incorrect: "Yanlış",
  entered_vin_number: "VIN numarası girildi",
  corrected_vin_number: "Revize VIN numarası",
  alternatives_vin_list_numbers: "Alternatif VIN numarası listesi",
  check_and_clicked_interested_vin_number: "",
  check: "Kontrol etmek",
  bg: "Bulgarca",
  ar: "Arapça",
  cs: "Çek",
  da: "Danimarkalı",
  de: "Almanca",
  el: "Yunan",
  en: "Ingiliz",
  es: "İspanyol",
  fr: "Fransızca",
  hu: "Macarca",
  it: "İtalyan",
  lt: "Litvanyalı",
  nl: "Flemenkçe",
  no: "Norveççe",
  pl: "Lehçe",
  pt: "Portekizce",
  ro: "Romence",
  ru: "Rusça",
  sk: "Slovak",
  sr: "Sırpça",
  sv: "İsveççe",
  tr: "Türkçe",
  uk: "Ukrayna",
};
