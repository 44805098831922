<template>
  <div class="d-xl-flex justify-content-xl-center align-items-xl-center precheck">
    <div class="p-4
        d-flex d-xl-flex
        flex-column
        justify-content-xl-center
        align-items-xl-center
        bg-danger
        border
        rounded
        border-0
      " style="width: 100%; height: 100%; padding: 10px">
      <div>
        <div>
          <h3 class="
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
              text-white
            " style="margin-bottom: 0px" v-html="t('we_have_vehicle_history_report_for_you')"></h3>
          <h4 class="
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
              text-white
            " style="margin-top: 10px; margin-bottom: 10px">
            {{ vin }}
          </h4>
        </div>
        <ul class="list-group" style="background: transparent">
          <li class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            " style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0;
              padding: 3px 10px;
            ">
            <span>{{ t("incidnets_history") }}</span>
            <i :class="damages > 0 ? 'bi-check-lg' : 'bi-x-lg'" class="bi" style="margin-left: 5px"></i>
          </li>
          <li class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            " style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0;
              padding: 3px 10px;
            ">
            <span>{{ t("vehicle_odometer") }}</span>
            <i :class="odometers > 0 ? 'bi-check-lg' : 'bi-x-lg'" class="bi" style="margin-left: 5px"></i>
          </li>
          <li class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            " style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0px;
              padding: 3px 10px;
            ">
            <span>{{ t("vehicle_inspection") }}</span>
            <i :class="events > 0 ? 'bi-check-lg' : 'bi-x-lg'" class="bi" style="margin-left: 5px"></i>
          </li>
          <li class="
              list-group-item
              d-flex d-xl-flex
              justify-content-center justify-content-xl-center
            " style="
              background: transparent;
              color: var(--bs-list-group-bg);
              border-width: 0px;
              padding: 3px 10px;
            ">
            <span>{{ t("archive_photos") }}</span>
            <i :class="photos > 0 ? 'bi-check-lg' : 'bi-x-lg'" class="bi" style="margin-left: 5px"></i>
          </li>
        </ul>
      </div>
      <a class="btn btn-dark" type="button" target="_blank" style="margin-top: 5px"
        :href="`${redirPartnerLink}/vin:${vin}`">
        {{ t("check_now") }}
      </a>
    </div>
  </div>
</template>
  
<script>
import { computed, ref, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { partnerLink } from "../utils/config";
export default {
  name: "FastPrecheck",
  setup() {
    const store = useStore();

    const damages = computed(() => {
      console.log(store.state.precheck);
      return store.state.precheck.damages;
    });
    const events = computed(() => {
      return store.state.precheck.events;
    });
    const odometers = computed(() => {
      return store.state.precheck.odometers;
    });
    const photos = computed(() => {
      return store.state.precheck.photos;
    });
    const vin = computed(() => {
      return store.state.vin;
    });

    const lang = computed(() => {
      return store.state.lang;
    });

    const { t } = useI18n();

    const redirPartnerLink = ref("x");
    redirPartnerLink.value = partnerLink(lang.value);

    watchEffect(() => {
      if (lang.value != navigator.language) {
        redirPartnerLink.value = partnerLink(lang.value);
      }
    });

    return {
      damages,
      events,
      odometers,
      photos,
      vin,
      t,
      redirPartnerLink,
    };
  },
};
</script>
  
<style scoped>
.precheck {
  -webkit-box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
}
</style>