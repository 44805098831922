import { createApp } from "vue";
import store from "./store/store";
import VueRecaptcha from "vue3-recaptcha-v2";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes/Routes";
import { i18n } from "./utils/langs";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css'
//testowa captcha 6LfkJu0dAAAAAK1hXWw7Da5nR8tPGixGq6oOZ1R5

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(VueRecaptcha, { siteKey: "6LfPxZIiAAAAADxtA-jjAUizfL3GfcQRJiuBR3kI" })
  .mount("#app");
