<template>
  <div class="container pt-4 pt-xl-5">
    <div class="row mb-5">
      <div class="col-md-8 col-xl-6 text-center mx-auto">
        <h2>{{ t("result_of_valid_vin_number") }}</h2>
        <p class="w-lg-50">
          {{ t("entered_number") }}&nbsp;<strong>{{ t("vin") }}</strong>&nbsp;{{ t("proved_to_be") }}&nbsp;<span
            class="fw-bold" :class="{ 'text-danger': !valid, 'text-success': valid }">{{ !valid ? t("incorrect") :
              t("correct") }}</span>.
          <span v-if="!valid">{{ t("check_and_clicked_interested_vin_number") }}:</span>
        </p>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-md-6 col-md-offset-6 col-sm-12">
        <FastPrecheck />
      </div>
    </div>
    
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import FastPrecheck from './FastPrecheck';

export default {
  name: "ValidResult",
  components: {
    FastPrecheck,
  },
  props: {
    valid: Boolean,
    precheck: Object,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const vinNumber = computed(() => {
      return store.state.vin;
    });

    return {
      t,
      vinNumber,
    };
  },
};
</script>

<style></style>