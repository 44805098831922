export const ru = {
  car_valuation: "ОЦЕНКА АВТОМОБИЛЯ",
  toggle_navigation: "Переключить навигацию",
  vin: "ВИН",
  plate: "Стол",
  mileage: "Пробег",
  first_reg_date: "дата первой регистрации",
  check_valuation: "Проверить цитату",
  contact: "Контакт",
  privacy_policy: "политика конфиденциальности",
  regulations: "Статут",
  valuation: "оценка",
  min_price: "Минимальная цена",
  med_price: "Средняя цена",
  max_price: "Максимальная цена",
  we_have_report_for_you: "У нас есть отчет по вашему автомобилю!",
  incident_history: "История инцидента",
  odometer_reading: "Чтение пробега",
  vehicles_inspection: "Осмотр транспортных средств",
  archive_photos: "Архивные фотографии",
  check_now: "ПРОВЕРЬ СЕЙЧАС!",
  unfortunately_not_found_desc:
    "К сожалению, мы не нашли год выпуска этого автомобиля, пожалуйста, введите его в поле выше.",
  type_odometer:
    "Введите текущий пробег автомобиля в километрах, чтобы лучше оценить его стоимость.",
  check_another_vin: "Проверить другой VIN",
  type_plate: "Введите номерной знак транспортного средства.",
  type_first_reg_date:
    "Введите дату первой регистрации транспортного средства.",
  price_odometer: "Цена/пробег",
  price_year: "Цена / год",
  year: "Год",
  vehicle_history_report: "Отчет об истории автомобиля",
  click: "Нажмите",
  here: "Здесь",
  and_check_vehicle_history: "и проверьте историю автомобиля",
  decode_your_number: "Расшифруй свой номер",
  for_free: "совершенно бесплатно",
  check_vehicle_valuation: "Проверьте стоимость вашего автомобиля на сайте",
  stolen_cars_database: "База данных угнанных автомобилей",
  check_if_your_vehicle_is_in_stolen_database: "Проверить, видит ли автомобиль разыскиваемые устройства",
  link: "соединять",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "ВИН - номер шасси",
  first_reg_plate: "дата первой регистрации",
  get_discount_code: "Получить код скидки",
  incidnets_history: "История инцидента",
  vehicle_odometer: "Пробег автомобиля",
  vehicle_inspection: "Осмотр транспортного средства",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Пожалуйста, проверьте правильность вашего VIN номера",
  enter_your_vin_number: "Введите свой VIN-номер",
  result_of_valid_vin_number: "Результат правильности VIN номера",
  entered_number: "Введенный номер",
  proved_to_be: "он оказался",
  correct: "Правильный",
  incorrect: "Неправильно",
  entered_vin_number: "введен вин номер",
  corrected_vin_number: "Пересмотренный VIN-номер",
  alternatives_vin_list_numbers: "Альтернативный список номеров VIN",
  check_and_clicked_interested_vin_number: "",
  check: "Проверять",
  bg: "Болгарский",
  ar: "Арабский",
  cs: "Чешский",
  da: "Датский",
  de: "Немецкий",
  el: "Греческий",
  en: "Британский",
  es: "Испанский",
  fr: "Французский",
  hu: "Венгерский",
  it: "Итальянский",
  lt: "Литовский язык",
  nl: "Голландский",
  no: "Норвежский язык",
  pl: "Польский",
  pt: "Португальский",
  ro: "Румынский",
  ru: "Русский",
  sk: "Словацкий",
  sr: "Сербский",
  sv: "Шведский",
  tr: "Турецкий",
  uk: "Украинец",
};
