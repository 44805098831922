export const sv = {
  car_valuation: "FORDONSVÄRDERING",
  toggle_navigation: "Växla navigering",
  vin: "VIN",
  plate: "Tabell",
  mileage: "Miltal",
  first_reg_date: "datum för första registrering",
  check_valuation: "Kolla offerten",
  contact: "Kontakt",
  privacy_policy: "integritetspolicy",
  regulations: "Stadga",
  valuation: "Värdering",
  min_price: "Minimipris",
  med_price: "Genomsnittspris",
  max_price: "Maxpris",
  we_have_report_for_you: "Vi har en rapport för ditt fordon!",
  incident_history: "Incidentens historia",
  odometer_reading: "Kilometeravläsning",
  vehicles_inspection: "Fordonsbesiktningar",
  archive_photos: "Arkivfoton",
  check_now: "KOLLA NU!",
  unfortunately_not_found_desc:
    "Tyvärr har vi inte hittat tillverkningsåret för detta fordon, vänligen ange det i fältet ovan.",
  type_odometer:
    "Ange fordonets aktuella körsträcka i kilometer för en bättre uppskattning av dess värde.",
  check_another_vin: "Kontrollera ett annat VIN",
  type_plate: "Ange fordonets registreringsskylt.",
  type_first_reg_date: "Ange datum för första registreringen av fordonet.",
  price_odometer: "Pris / körsträcka",
  price_year: "Pris/år",
  year: "År",
  vehicle_history_report: "Správa o histórii vozidla",
  click: "Kliknite",
  here: "Tu",
  and_check_vehicle_history: "a skontrolujte históriu vozidla svojho auta",
  decode_your_number: "Dekódujte svoje číslo",
  for_free: "úplne zadarmo",
  check_vehicle_valuation:
    "Skontrolujte si ocenenie svojho vozidla na webovej stránke",
  stolen_cars_database: "Databáza ukradnutých vozidiel",
  check_if_your_vehicle_is_in_stolen_database: "Kontrollera om fordonet ser önskade enheter",
  link: "odkaz",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - číslo podvozku",
  first_reg_plate: "dátum prvej registrácie",
  get_discount_code: "Získajte zľavový kód",
  incidnets_history: "História incidentov",
  vehicle_odometer: "Počet najazdených kilometrov vozidla",
  vehicle_inspection: "Kontrola vozidla",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Skontrolujte si prosím správnosť vášho VIN čísla",
  enter_your_vin_number: "Zadajte svoje VIN číslo",
  result_of_valid_vin_number: "Výsledok správnosti VIN čísla",
  entered_number: "Zadané číslo",
  proved_to_be: "ukázal sa byť",
  correct: "Správne",
  incorrect: "Nesprávne",
  entered_vin_number: "zadané VIN číslo",
  corrected_vin_number: "Upravené VIN číslo",
  alternatives_vin_list_numbers: "Alternatívny zoznam VIN čísel",
  check_and_clicked_interested_vin_number: "",
  check: "Skontrolujte",
  bg: "Bulharčina",
  ar: "Arabčina",
  cs: "Český",
  da: "Dánčina",
  de: "Nemecký",
  el: "Grécky",
  en: "Britský",
  es: "Španielčina",
  fr: "Francúzsky",
  hu: "Maďarský",
  it: "Taliansky",
  lt: "Litovský",
  nl: "Holandský",
  no: "Nórsky",
  pl: "Poľský",
  pt: "Portugalčina",
  ro: "Rumunský",
  ru: "Ruský",
  sk: "Slovenský",
  sr: "Srbský",
  sv: "Švédsky",
  tr: "Turecký",
  uk: "Ukrajinčina",
};
