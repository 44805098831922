export const pt = {
  car_valuation: "AVALIAÇÃO DO VEÍCULO",
  toggle_navigation: "Alternar de navegação",
  vin: "VIN",
  plate: "Mesa",
  mileage: "Quilometragem",
  first_reg_date: "data do primeiro registro",
  check_valuation: "Confira a cotação",
  contact: "Contato",
  privacy_policy: "política de Privacidade",
  regulations: "Estatuto",
  valuation: "Avaliação",
  min_price: "Preço mínimo",
  med_price: "Preço médio",
  max_price: "Preço máximo",
  we_have_report_for_you: "Temos um relatório para o seu veículo!",
  incident_history: "Histórico de incidentes",
  odometer_reading: "Leitura de quilometragem",
  vehicles_inspection: "Inspeções de veículos",
  archive_photos: "Fotos de arquivo",
  check_now: "VERIFIQUE AGORA!",
  unfortunately_not_found_desc:
    "Infelizmente, não encontramos o ano de produção deste veículo, digite-o no campo acima.",
  type_odometer:
    "Insira a quilometragem atual do veículo em quilômetros para uma melhor estimativa de seu valor.",
  check_another_vin: "Verifique um VIN diferente",
  type_plate: "Digite a placa do veículo.",
  type_first_reg_date: "Insira a data do primeiro registro do veículo.",
  price_odometer: "Preço / quilometragem",
  price_year: "Preço / ano",
  year: "Ano",
  vehicle_history_report: "Relatório de histórico do veículo",
  click: "Clique",
  here: "Aqui",
  and_check_vehicle_history: "e verifique o histórico do veículo do seu carro",
  decode_your_number: "Decodifique seu número",
  for_free: "completamente grátis",
  check_vehicle_valuation: "Consulte a avaliação do seu veículo no site",
  stolen_cars_database: "Banco de dados de veículos roubados",
  check_if_your_vehicle_is_in_stolen_database: "Verifique se o veículo vê dispositivos procurados",
  link: "ligação",
  complete_the_form_and_get_dc: "",
  vin_placeholder: "VIN - número do chassi",
  first_reg_plate: "data do primeiro registro",
  get_discount_code: "Obtenha um código de desconto",
  incidnets_history: "Histórico de incidentes",
  vehicle_odometer: "quilometragem do veículo",
  vehicle_inspection: "Inspeção veicular",
  we_have_vehicle_history_report_for_you: "",
  check_your_vin_number: "Por favor, verifique a exatidão do seu número VIN",
  enter_your_vin_number: "Digite seu número VIN",
  result_of_valid_vin_number: "O resultado da exatidão do número VIN",
  entered_number: "Número digitado",
  proved_to_be: "ele acabou por ser",
  correct: "Correto",
  incorrect: "Incorreta",
  entered_vin_number: "Número VIN inserido",
  corrected_vin_number: "Número VIN revisado",
  alternatives_vin_list_numbers: "Lista alternativa de números VIN",
  check_and_clicked_interested_vin_number: "",
  check: "Verificar",
  bg: "Búlgaro",
  ar: "Árabe",
  cs: "Tcheco",
  da: "Dinamarquês",
  de: "Alemão",
  el: "Grego",
  en: "Britânico",
  es: "Espanhol",
  fr: "Francês",
  hu: "Húngaro",
  it: "Italiano",
  lt: "Lituano",
  nl: "Holandês",
  no: "Norueguês",
  pl: "Polonês",
  pt: "Português",
  ro: "Romena",
  ru: "Russo",
  sk: "Eslovaco",
  sr: "Sérvio",
  sv: "Sueco",
  tr: "Turco",
  uk: "Ucraniano",
};
